export const linkData = [
  {
      id: 1,
      text: 'Home',
      url: '/'
  },
  {
      id: 2,
      text: 'All Lessons',
      url: '/listoflessons'
  },
  {
    id: 3,
    text: 'Free English Resources',
    url: '/convo'
  }
];


